import './js/components/intro-return.js';
import './js/components/navbar.js';
import './js/components/sante-prev-scrollmagic.js';
import './js/components/scrollto.js';
import './js/components/typeform-block.js';


const app = async () => {
    $('.menu-navbar__top-level__link[data-page="sante-prev"]').addClass('is-active');
};
app();
