// function getCookieValue(a) {
//     var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
//     return b ? b.pop() : '';
// }

// const lastApp = getCookieValue('lastAppVisited');

// $('.intro-return').hide(0);

// switch (lastApp) {
//     case 'PPA':
//         $('.intro-return--adp').show(0);
//         break;
//     case 'TOUTM':
//         $('.intro-return--toutm').show(0);
//         break;
//     case 'PCPME':
//         $('.intro-return--pcpme').show(0);
//         break;
//     case 'OLDPCPME':
//         $('.intro-return--pcpme-old').show(0);
//         break;
//     default:
//         $('.intro-return--unknown').show(0);
// }

/**
 * function scroll to espace-action-base
 */
$('.banner-chevron').on('click', function () {
    var body = $(document.documentElement, document.body);
    body.animate({ scrollTop: $(".espace_action").offset().top}, 1000);
});
