if ($(window).outerWidth() >= 1024) {
    var SMController = new ScrollMagic.Controller();

    $('.espace-action').each(function () {
        var direction = $(this).css('justify-content') == 'normal' ? 1 : -1;
        var tl = new TimelineMax()
            .from($(this).find('.thumb '), 1, { x: -100 * direction }, 0)
            .from($(this).find('.content '), 1, { x: 200 * direction }, 0)
            .from($(this), 0.5, { opacity: 0 }, 0)
            .duration(0.4);

        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.75
        })
            .setTween(tl)
            .addTo(SMController);
    });
}